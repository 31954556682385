import React, {useRef, useState} from 'react'
import './Authorization.scss'
import axios from 'axios'
import { toast } from 'react-toastify';
import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import img_checked from '../../assets/checked.png'

function Authorization() {
    const [userPhoneNumber, setPhone] = useState('');
    const [userCode, setCode] = useState('');
    const [status, setStatus] = useState('');

    const phoneInput = useRef(null);
    const codeInput = useRef(null);

    const sendCode = async (event) => {
        if(userPhoneNumber === undefined || !isValidPhoneNumber(userPhoneNumber)){
            toast.error("Invalid phone number. Please make sure you've entered your data correctly and try again.");
            return;
        }

        event.target.disabled = true;
        phoneInput.current.disabled = true;

        const cleanPhone = userPhoneNumber.replace("+", "");
        
        const data = {
            user_phone: cleanPhone
        }

        axios.post(`${process.env.REACT_APP_API_URL}/user/verification/request`, data).then((response) => {
            const resStatus = response.data.status;
            setStatus(resStatus);
            console.log(resStatus);
            if(resStatus === 'awaiting'){
                toast.success("Verification code is on it's way! Open your WhatsApp app.");
            }
            else if (resStatus === 'verified'){
                toast.success("This phone number was already verified!")
            }
            event.target.disabled = false;
        }).catch((error) => {
            toast.error(error.response.data);
        });
    }

    const confirmCode = async (e) => {
        e.target.disabled = true;
        codeInput.current.disabled = true;

        const cleanPhone = userPhoneNumber.replace("+", "");

        const data = {
            user_phone: cleanPhone,
            code: userCode
        }

        axios.post(`${process.env.REACT_APP_API_URL}/user/verification/confirm`, data).then((response) => {
            toast.success("Verified successfully!");
            setStatus('verified');
        }).catch((error) => {
            toast.error(error.response.data);
        });
    }

    const handleCodeChange = (e) => {
        setCode(e.target.value);
    }

    return (
        <div>
            <div className="wrapper">
                <div className="form container">
                    {status === 'awaiting'
                        ? (
                            <header>
                                <h3 className='title'>Authorization</h3>
                                <p className='description'>We send you a verification code to your WhatsApp app. Please confirm the code below.</p>
                            </header>
                        )
                        : status === 'verified'
                        ? (
                            <header>
                                <h3 className='title'>Authorization</h3>
                                <p className='description'>Thank you for verifying your phone number. You can chat with the chatbot now.</p>
                            </header>
                        )
                        : (
                            <header>
                                <h3 className='title'>All done!</h3>
                                <p className='description'>Thank you for choosing our product! Before we start, please verify your WhatsApp number.</p>
                            </header>
                        )
                    }
                    {
                        status !== "verified"
                        ? (
                            <main>
                                <div className="inputs">
                                    <div className="phone input-group">
                                        <label htmlFor="userPhoneNumber">WhatsApp Number:</label>
                                        <PhoneInput
                                            ref={phoneInput}
                                            placeholder="Enter phone number"
                                            value={userPhoneNumber}
                                            onChange={setPhone}
                                            id="userPhoneNumber"/>
                                    </div>

                                    {status === 'awaiting'
                                        ? (<div className="confirmation input-group">
                                            <label htmlFor="userConfirmation">Confirmation Code:</label>
                                            <input type="text" ref={codeInput} name="userConfirmation" id="userConfirmation" placeholder='000000' value={userCode} onChange={handleCodeChange}/>
                                        </div>)
                                        : (<div></div>)
                                    }

                                    {
                                        status === 'awaiting'
                                        ? (<button className='btn' onClick={confirmCode}>Confirm</button>)
                                        : (<button className='btn' onClick={sendCode}>Submit</button>)
                                    }
                                </div>
                            </main>  
                        )
                        : (
                            <main>
                                <img className='confirmed' src={img_checked} alt=""></img>
                                <p>All done! Click the button bellow and say "Hi" to the chatbot to start your journey.</p>
                                <div className='inputs'>
                                    <a className='btn' href={process.env.REACT_APP_WA_CHATBOT_URL}>Go to WhatsApp</a>
                                </div>
                            </main>
                        )
                    }
                </div> 
            </div>
        </div>
    )
}

export default Authorization